export interface Tier {
  name: string;
  id: "starter" | "pro" | "advanced";
  icon: string;
  description: string;
  features: string[];
  featured: boolean;
  priceId: Record<string, string>;
}

export const PricingTier: Tier[] = [
  {
    name: "Pro",
    id: "pro",
    icon: "/assets/icons/price-tiers/basic-icon.svg",
    description: "Enhanced design tools for scaling teams who need more flexibility.",
    features: ["Integrations", "Unlimited workspaces", "Advanced editing tools", "Everything in Starter"],
    featured: true,
    priceId: { month: "pri_01jjdscat13f4skftt1qrs68jg", year: "pri_01jjgza2sy7e6m1v3h5zewv20r" },
  },
];
