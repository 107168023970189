import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/styles/prosemirror.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@15.1.4_next@14.2.9_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0_dou7zaq24bzwmhr6sxzk5lasii/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@15.1.4_next@14.2.9_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0_dou7zaq24bzwmhr6sxzk5lasii/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@15.1.4_next@14.2.9_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0_dou7zaq24bzwmhr6sxzk5lasii/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.9_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.83.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/.pnpm/sonner@0.7.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/sonner/dist/index.mjs");
